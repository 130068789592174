import React from 'react'
import Nav from '../components/Nav'

export default function About(props) {
  return (
    <div id='About'>
        <Nav />
   <h3> This Application was created to have a safe space to submit periodic journal entries to track and view various feelings and states during the time
   </h3>

   

    </div>
  )
}